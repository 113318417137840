<template>
    <b-modal centered
             ref="produce-modal"
             id="produce-modal"
             class="produce-modal"
             @hide="onHide"
    >

        <template #modal-header>
            <div>
                Обклеено
            </div>
        </template>

        <div v-if="task">
            <div class="form-group" style="margin-bottom: 0;">
                <div class="input-group" style="line-height: 35px;">
                    <input type="number" class="form-control" v-model="producedCount" :min="1">
                    <span class="ml-1 mr-1" style="font-weight: 600;font-size: 25px;">/ {{ leftToProduce }}</span>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <div>
                <button class="btn btn-success" @click="produce" :disabled="!producedCount">Сохранить</button>
                <button class="btn btn-secondary" @click="hide">Отмена</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import {sumBy} from 'lodash'
    export default {
        props: {
            task: {
                type: Object
            }
        },
        watch: {
            task(value) {
                if (value) {
                    this.$refs['produce-modal'].show()
                }
            }
        },
        data() {
            return {
                producedCount: null
            }
        },
        computed: {
            leftToProduce() {
                return this.produced > this.task.required ? 0 : parseInt(this.task.required) - this.produced;
            },
            produced() {
                return sumBy(this.task.acts, a => sumBy(a.items, i => parseInt(i.count)));
            },
        },
        methods: {
            produce() {
                this.$emit('produced', {task: this.task, count: this.producedCount});

                this.hide()
            },
            hide() {
                this.$refs['produce-modal'].hide()
            },
            onHide() {
                this.producedCount = null
                this.$emit('hide')
            },
        }
    }
</script>

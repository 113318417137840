<template>
    <b-modal centered
            hide-footer
            ref="print-labels-modal"
            id="print-labels-modal"
            class="print-labels-modal"
            @hide="actionsAfterHideModalPrintLabels()"
    >
        <div class="errors-output" v-if="errorLabelsPrint !== null">
            <b>Ошибка: {{ errorLabelsPrint }}</b>
        </div>
        <div style="text-align:center;margin-top:20px;">
            <label style="margin-bottom:10px">Выберите принтер для печати этикетки:</label>
            <div>
                <b-form-select v-model="selectedTypeOfPrinters" :options="typesOfPrinters"></b-form-select>
            </div>
        </div>
        <div style="text-align:center;margin-top:20px">
            <label style="margin-bottom:10px">Выберите тип этикетки:</label>
            <div class="type-of-labels-wrapper">
                <div class="type-of-labels-icon">
                    <i class="fas fa-circle" v-if="selectedTypeOfLabels === 'round'"></i>
                    <i class="fas fa-band-aid" v-else-if="selectedTypeOfLabels === 'tureen'"></i>
                    <i class="fas fa-square" v-else-if="selectedTypeOfLabels === 'rectangle'"></i>
                    <i
                        class="fas fa-wheelchair"
                        style="color:#900;"
                        v-else-if="selectedTypeOfLabels === 'round_robinbobin'"
                    ></i>
                    <i
                        class="fas fa-blind"
                        style="color:#900;"
                        v-else-if="selectedTypeOfLabels === 'rectangular_robinbobin'"
                    ></i>
                    <i class="fas fa-times" style="color:#900;" v-else></i>
                </div>
                <div class="type-of-labels-select">
                    <b-form-select v-model="selectedTypeOfLabels" :options="typesOfLabels">
                        <template #first>
                            <option :value="null" disabled>Выберите тип</option>
                        </template>
                    </b-form-select>
                </div>
            </div>
        </div>
        <div style="text-align:center;margin-top:20px;">
            <label style="margin-bottom:10px">Введите количество этикеток:</label>
            <input
                    type="number"
                    class="form-control"
                    placeholder="Введите количество"
                    v-model="numberOfRequiredLabels"
            />
        </div>
        <div style="margin-top:30px;text-align:right;">
            <button class="btn btn-primary" @click="labelsPrint()">Отправить на печать</button>
        </div>
    </b-modal>
</template>

<script>
    import { PRINT_QUEUES } from '../../utils/endpoints';

    const NOVEXX = 'novexx';
    const ZEBRA_ZT410 = 'zebra_zt410';
    const RESERVED = 'reserved';

    const CIRCLE = 'round';
    const TUREEN = 'tureen';
    const RECTANGLE = 'rectangle';
    const ROUND_ROBINBOBIN = 'round_robinbobin';
    const RECTANGULAR_ROBINBOBIN = 'rectangular_robinbobin';

    export default {
        props: {
            task: {
                type: Object
            }
        },
        watch: {
            task(value) {
                if (value) {
                    this.$refs['print-labels-modal'].show()
                }
            }
        },
        computed: {
            typesOfPrinters() {
                return [
                    { value: NOVEXX, text: 'Novexx' },
                    { value: ZEBRA_ZT410, text: 'Zebra ZT410' },
                    { value: RESERVED, text: 'Резервный' }
                ];
            },
            typesOfLabels() {
                return [
                    { value: RECTANGLE, text: 'Прямоугольная' },
                    { value: CIRCLE, text: 'Круглая' },
                    { value: TUREEN, text: 'Супница' },
                    { value: ROUND_ROBINBOBIN, text: 'Круглая (Робин Бобин)' },
                    { value: RECTANGULAR_ROBINBOBIN, text: 'Прямоугольная (Робин Бобин)' }
                ];
            },
        },
        data() {
            return {
                errorLabelsPrint: null,
                selectedTypeOfLabels: null,
                numberOfRequiredLabels: null,
                selectedTypeOfPrinters: NOVEXX,
            };
        },
        methods: {
            validateLabelsPrint() {
                if ( this.selectedTypeOfLabels === null ) {
                    this.errorLabelsPrint = 'Необходимо выбрать тип этикетки';

                    return false;
                }

                if ( this.numberOfRequiredLabels === null ) {
                    this.errorLabelsPrint = 'Необходимо ввести количество этикеток';

                    return false;
                }

                if ( this.numberOfRequiredLabels <= 0 ) {
                    this.errorLabelsPrint = 'Количество этикеток должно быть больше 0';

                    return false;
                }

                return true;
            },
            labelsPrint() {
                if ( this.validateLabelsPrint() === true ) {
                    let params = {
                        new_rules: true,
                        labels_type: this.selectedTypeOfLabels,
                        printer_type: this.selectedTypeOfPrinters,
                        number_of_labels: this.numberOfRequiredLabels,
                        production_task: { id: this.task.ids[ 0 ] },
                        production_plan_markup_preset: { id: this.task.production_plan_markup_preset_id },
                    };

                    this.$http
                        .post( PRINT_QUEUES + '/append-to/front', params )
                        .then(
                            () => {
                                this.$refs[ 'print-labels-modal' ].hide();
                                this.$emit( 'printed', this.task );
                                this.actionsAfterHideModalPrintLabels();
                            },
                            response => this.errorLabelsPrint =
                                response.body.errors && response.body.errors[ 0 ] ?
                                    response.body.errors[ 0 ] : 'Неизвестная ошибка во время создания заданий на печать'
                        );
                }
            },
            actionsAfterHideModalPrintLabels() {
                this.errorLabelsPrint = null;
                this.selectedTypeOfLabels = null;
                this.numberOfRequiredLabels = null;
                this.selectedTypeOfPrinters = NOVEXX;
                this.$emit('hide')
            },
        }
    }
</script>

<style scoped lang="sass">
    .type-of-labels-wrapper
        display: flex
        align-items: center
        justify-content: space-between

        .type-of-labels-icon
            width: 5%
            display: flex
            justify-content: center

        .type-of-labels-select
            width: 95%
            display: flex

    .errors-output
        color: #900
        text-align: center
        margin-bottom: 15px
</style>

<template>
    <tr>
        <td style="width: 1px;">
            <b-checkbox
                v-if="selectable"
                @change="$emit('toggleSelection', $event)"
                :checked="checked"
            ></b-checkbox>
        </td>
        <td style="width: 1px">
            <button class="btn" :class="printBtnClass" @click="print">
                <i class="fas fa-print"></i>
            </button>
        </td>
        <td style="position: relative" @click="select">
            <span style="font-weight: 600;">{{ markupPreset }}</span>
            <br>
            <span>{{barcode}}</span>

            <span style="position: absolute; right: 5px; bottom: 5px; font-size: x-small;">
                {{ productionStartAt }} – {{ productionEndAt }}
            </span>
        </td>
        <td style="width: 1px" @click="select">
            {{ city }}
        </td>
        <td class="text-right" style="width: 1px" @click="produce" :class="task.productionActs.length > 0 ? 'link' : ''">
            {{ producedCount }}&nbsp;/&nbsp;{{ task.count }}
        </td>
        <td class="text-center">
            <button v-if="selectable" class="btn btn-success" @click="$emit('transfer')">
                <i class="fas fa-share-square"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import moment from 'moment';
    import {sumBy} from 'lodash'

    export default {
        name: "LabelingItem2",
        props: {
            task: {
                required: true,
                type: Object
            },
            checked: {
                type: Boolean,
                default: false
            },
            selectable: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            printBtnClass() {
                if (this.task.productionActs.length === 0) {
                    return 'btn-primary'
                }

                if (parseFloat(this.task.count) <= sumBy(this.task.productionActs, pa => sumBy(pa.items, i => parseInt(i.count)))) {
                    return 'btn-success'
                }

                return 'btn-warning'
            },
            markupPreset() {
                let mealDay = this.task.productionPlanMarkupPreset.markupPreset.meal_day;
                let dayOfWeek = this.getWeekDayInStrType(this.task.productionPlanMarkupPreset.markupPreset.day_of_week);
                let productionLine = this.task.productionPlanMarkupPreset.markupPreset.production_line_to_print.toUpperCase();

                return `${productionLine} ${dayOfWeek} ${mealDay}`;
            },
            barcode() {
                return this.task.productionPlanMarkupPreset.markupPreset.barcode;
            },
            producedCount() {
                return sumBy(this.task.productionActs, a => sumBy(a.items, i => parseInt(i.count)));
            },
            city() {
                switch (this.task.productionPlanMarkupPreset.markupPreset.city) {
                    case 'Санкт-Петербург':
                        return 'СПБ';
                    case 'Москва':
                        return 'МСК';
                    case 'Колпино':
                        return 'КЛП';
                }

                return '---'
            },
            productionEndAt() {
                return moment( this.task.production_end_at ).format('HH:mm');
            },
            productionStartAt() {
                return moment( this.task.production_start_at ).format('HH:mm');
            },
        },
        methods: {
            getWeekDayInStrType( dayOfWeek ) {
                let days = [ 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС' ];

                return days[ dayOfWeek ];
            },
            print() {
                this.$emit('print')
            },
            select() {
                this.$emit('select')
            },
            produce() {
                this.$emit('produce')
            }
        }
    }
</script>

<style scoped lang="sass">
    td
        vertical-align: middle

    .link:hover
        cursor: pointer
</style>

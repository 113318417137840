<template>
    <div class="card">
        <div>
            <div class="deadline-control">
                <div @click="fetchDeadline(filters.deadline, 'prev')"
                     style="flex-basis: 13%" class="text-center">
                    <i class="fas fa-arrow-circle-left  "></i>
                </div>
                <div style="flex-basis: 49%" class="text-center">
                    <template v-if="!deadlineLoading">
                        <i class="fas fa-truck mr-1" style="font-size: large"></i>
                        {{ moment(filters.deadline).format('DD.MM.YY HH:mm') }}
                    </template>
                    <simple-loader v-else></simple-loader>
                </div>
                <div @click="fetchDeadline()"
                     style="flex-basis: 13%" class="text-center">
                    <i class="fas fa-arrow-circle-right"></i>
                </div>
                <div @click="loadTasks"
                     style="flex-basis: 15%"
                     class="text-center">
                    <i class="fas fa-sync"></i>
                </div>
                <div>
                    <b-nav-item-dropdown right style="list-style: none;" no-caret>
                        <template #button-content>
                            <i class="fas fa-list"></i>
                        </template>
                        <b-dropdown-item v-b-modal.date_modal>
                            Изменить дату
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </div>
            </div>

            <div class="labeling-list-flex">
                <div class="labeling-list table-responsive">
                    <table class="table table-bordered" style="margin-bottom: 0;" v-if="!tasksLoading">
                        <tbody>
                            <tr>
                                <td style="width: 1px;">
                                    <b-checkbox
                                        v-if="selectableTasksCount"
                                        @change="selectAllTasks($event)"
                                        :checked="allSelected"
                                        :indeterminate="selectionIndeterminate"
                                    ></b-checkbox>
                                </td>
                                <td colspan="4">
                                </td>
                                <td class="text-center" style="width: 1px;">
                                    <button
                                        v-if="Object.keys(selectedTasks).length"
                                        @click="transferBatch"
                                        class="btn btn-xs btn-success">
                                        <i class="fas fa-dolly-flatbed"></i>
                                        {{totalTransferCount}}
                                    </button>
                                </td>
                                <td style="width: 1px;"></td>
                            </tr>
                            <template v-for="(nomenclature, index) in productionTasks" class="card">
                                <tr @click="selectNomenclature(nomenclature)"
                                    :class="{'bg-success' : selectedNomenclature && selectedNomenclature.id === nomenclature.id }"
                                    style="border-top: 2px solid black;"
                                >
                                    <td colspan="3" style="border-right: none">
                                        <h4 style="margin-bottom: 0">{{ nomenclature.name }}</h4>
                                    </td>
                                    <td colspan="3" style="border-left: none; vertical-align: bottom">
                                        <h5 style="margin-bottom: 0">
                                            {{ producedCount(nomenclature.tasks) }} / {{ requiredCount(nomenclature.tasks) }}
                                        </h5>
                                    </td>
                                    <td style="width: 1px;"></td>
                                </tr>
                                <tr v-for="(task, subindex) in nomenclature.tasks">
                                    <td style="width: 1px">
                                        <b-checkbox :checked="!!selectedTasks[`${index}.${subindex}`]" v-if="taskSelectable(task)" @change="toggleSelection($event, task, index, subindex)"></b-checkbox>
                                    </td>
                                    <td style="width: 1px">
                                        <button
                                            title="Печать"
                                            @click="print(task)"
                                            :class="{
                                                'btn-primary':task.productionActs.length === 0,
                                                'btn-success': producedByTask(task) >= task.required,
                                                'btn-warning': task.productionActs.length && producedByTask(task) < task.required
                                            }"
                                            class="btn">
                                            <i class="fas fa-print"></i>
                                        </button>
                                    </td>
                                    <td><b>{{task.label}}</b></td>
                                    <td>{{task.city}}</td>
                                    <td :class="{'link': task.productionActs.length}" @click="produce(task)">
                                        <span style="white-space: nowrap">
                                            {{producedByTask(task)}}&nbsp;/&nbsp;{{task.required}}
                                        </span>
                                    </td>
                                    <td>
                                        <button v-if="producedByTask(task) - transferredByTask(task) > 0" @click="transfer(task)" title="Передать" style="width: 100%" class="btn btn-success"><i class="fas fa-dolly-flatbed"> {{producedByTask(task) - transferredByTask(task)}}</i></button>
                                    </td>
                                    <td style="width: 1px;">
                                        <button v-if="nomenclatureLot(task) && packingAvailable" @click="startPacking(task)" class="btn"><i class="fas fa-inbox"></i> {{packedAmount(task)}}</button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="text-center" style="padding: 20px;" v-else="tasksLoading">
                        <simple-loader></simple-loader>
                    </div>
                </div>
                <div class="labeling-image">
                    <div class="card" style="position: sticky; top: 56px;" v-if="selectedNomenclature">
                        <div class="card-header" style="font-weight: 600">
                            {{ selectedNomenclature.name }}
                        </div>
                        <div class="card-body text-center">
                            <img :src="nomenclatureImage" style="max-width: 100%;" v-if="nomenclatureImage">
                            <simple-loader v-else="imageLoading"></simple-loader>
                            <div class="alert alert-info text-center" v-else style="margin-bottom: 0">Нет изображения</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <print-modal :task="taskToPrint"
                     @hide="taskToPrint = null"
                     @printed="onPrinted"
        >
        </print-modal>

        <produce-modal :task="taskToProduce"
                       @hide="taskToProduce = null"
                       @produced="onProduced"
        ></produce-modal>

        <b-modal centered
                 hide-footer
                 ref="date_modal"
                 id="date_modal"
                 class="print-labels-modal"
                 title="Выберите дату"
                 @hide="dateForDeadline = null"
        >
            <div class="form-group">
                <input type="date" class="form-control" v-model="dateForDeadline">
            </div>
        </b-modal>
        <modal name="packing"
               :adaptive="true"
               :scrollable="true"
               :height="'auto'"
               :maxHeight="300"
               @before-close="packableLot = null; packingTab = 'packing'">
            <div class="card mb-0">
                <div class="card-header">Сборка ящиков
                    <button class="btn pull-right" @click="$modal.hide('packing')"><i class="fas fa-times"></i></button></div>
                <div class="card-body" style="padding: 0 !important">
                    <div v-show="packingTab==='movement'">
                        <onto-zone :nomenclature-lot="packableLot"
                                   ref="movement"></onto-zone>
                    </div>
                    <div v-show="packingTab==='packing'">
                        <packing :nomenclature-lot="packableLot"
                                 ref="packing"
                                 @finish="packingInfo[$event.nomenclature_lot_id] = {count: $event.count, nomenclature_lot_id: $event.nomenclature_lot_id}; $forceUpdate();"
                                 :task-id="packableTaskId"
                                 :task-amount="packableTaskAmount"></packing>
                    </div>
                    <div style="height: 44px;"></div>
                    <control-panel>
                        <button class="btn"
                                @click="togglePacking()"
                                :class="{'btn-success': packingTab === 'packing'}">
                            <i class="fas fa-inbox"></i>
                        </button>
                        <button class="btn"
                                :class="{'btn-success': packingTab === 'movement'}"
                                @click="toggleMovement()"><i class="fas fa-pallet"></i></button>
                    </control-panel>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment';
    import { sumBy} from 'lodash'
    import { PRODUCTION_ACTS_ENDPOINT, PRODUCTION_PLANS_ENDPOINT} from "@utils/endpoints";
    import LabelingItem from './LabelingItem'
    import PrintModal from './PrintModal'
    import lsfMixin from "../../utils/localStorageFilterMixin";
    import SimpleLoader from "../_common/SimpleLoader";
    import ProduceModal from "./ProduceModal";
    import Packing from "../box/Packing/Packing";
    import OntoZone from "../box/Movement/OntoZone";
    import ControlPanel from "../_common/ControlPanel";

    const FILTERS_PATTERN = {
        deadline: null
    };

    export default {
        name: "LabelingList2",
        mixins: [ lsfMixin ],
        components: {
            ProduceModal,
            LabelingItem,
            PrintModal,
            SimpleLoader,
            Packing,
            OntoZone,
            ControlPanel,
        },
        computed: {
            packingAvailable() {
                return true;// this.packingInfo !== null;
            },
            nomenclatureImage() {
                return this.$store.getters['nomenclature/info/image'](this.selectedNomenclature.id)
            },
            selectionIndeterminate() {
                if (Object.keys(this.selectedTasks).length === 0) {
                    return false;
                }

                if (Object.keys(this.selectedTasks).length !== this.selectableTasksCount) {
                    return true;
                }

                return false;
            },
            allSelected() {
                if (Object.keys(this.selectedTasks).length === this.selectableTasksCount) {
                    return true;
                }

                return false;
            },
            selectableTasksCount() {
                let count = 0;
                this.productionTasks.map(n => {
                    n.tasks.map(t => {
                        if (this.taskSelectable(t)) {
                            count++;
                        }
                    });
                });

                return count;
            },
            totalTransferCount() {
                let count = 0;
                Object.keys(this.selectedTasks).map(k => {
                    count += this.producedByTask(this.selectedTasks[k]) - this.transferredByTask(this.selectedTasks[k]);
                });

                return count;
            },
        },
        watch: {
            packingTab(val) {
                if (val) {
                    this.$root.listenToBarcodeScanning(this.$refs[val]);
                }
            },
            filters: {
                deep: true,
                handler(value) {
                    this.watchFilters(value);
                }
            },
            selectedNomenclature(value) {
                if (value) {
                    this.imageLoading = true
                    this.$store.dispatch('nomenclature/info/getNomenclatureInfo', this.selectedNomenclature.id)
                        .finally(() => this.imageLoading = false)
                }
            },
            dateForDeadline(value) {
                if (value) {
                    this.fetchDeadline(value)
                    this.$refs['date_modal'].hide()
                }
            },
            packableLot(value) {
                if (value) {
                    this.$modal.show('packing');
                } else {
                    this.$modal.hide('packing');
                }
            },
        },
        data() {
            return {
                meta: {},
                productionTasks: [],
                taskToPrint: null,
                taskToProduce: null,
                markupPresetToPrint: null,
                selectedNomenclature: null,
                imageLoading: false,
                tasksLoading: false,
                deadlineLoading: false,
                dateForDeadline: null,
                selectedTasks: {},
                packableLot: null,
                packableTaskId: null,
                packableTaskAmount: null,
                packingInfo: null,
                packingTab: 'packing',
            };
        },

        methods: {
            moment,
            applyFilters() {
                if (this.filters.deadline) {
                    this.loadTasks()
                } else {
                    this.fetchDeadline()
                }
            },
            toggleSelection(value, task, index, subindex) {
                if (this.taskSelectable(task)) {
                    value ? this.$set(this.selectedTasks, `${index}.${subindex}`, task) : this.$delete(this.selectedTasks, `${index}.${subindex}`);
                }
            },
            selectAllTasks(value) {
                this.productionTasks.map((n, index) => {
                    n.tasks.map((t, subindex) => {
                        this.toggleSelection(value, t, index, subindex);
                    })
                });
            },
            taskSelectable(task) {
                if (task.productionActs.length) {
                    return this.producedByTask(task) - this.transferredByTask(task) > 0;
                }

                return false;
            },
            fetchDeadline(date = this.filters.deadline, type = 'next') {
                this.deadlineLoading = true
                this.$http.get(PRODUCTION_PLANS_ENDPOINT + '/deadline', {
                        params: {
                            type,
                            date,
                            without_loading: 1
                        }
                    })
                    .then(response => {
                        this.filters.deadline = response.data.data.deadline
                    })
                    .finally(() => {
                        this.deadlineLoading = false
                    })
            },
            loadTasks() {
                this.tasksLoading = true
                this.$http.get('v2/production_tasks/labeling', {
                        params: {
                            deadline: moment(this.filters.deadline).format('YYYY-MM-DD HH:mm'),
                            without_loading: 1
                        }
                    })
                    .then(response => {
                        this.productionTasks = response.data.list;
                        this.meta = response.data.meta;
                        this.packingInfo = response.data.packing;
                    })
                    .finally(() => {
                        this.tasksLoading = false
                    })
            },
            packedAmount(task) {
                if (!!this.nomenclatureLot(task)) {
                    if (!!this.packingInfo && !!this.packingInfo[this.nomenclatureLot(task).id]) {
                        return parseFloat(this.packingInfo[this.nomenclatureLot(task).id].count);
                    }
                }

                return 0;
            },
            print(task) {
                this.taskToPrint = task
            },
            onPrinted(task) {
                if (task.productionActs.length > 0) {
                    return;
                }

                this.createProductionAct(task)
            },
            produce(task) {
                if (task.productionActs.length === 0) {
                    return
                }

                this.taskToProduce = task;
            },
            onProduced({task, count}) {
                if (task.productionActs.length === 0) {
                    return
                }

                let productionAct = task.acts[0];
                productionAct.items.push({
                    plan_count: count,
                    count: count,
                });

                let params = {
                    items: productionAct.items,
                    end_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    use_proportional_raw_materials: 1,
                };

                this.$http.put(PRODUCTION_ACTS_ENDPOINT + `/${productionAct.id}/produce`, params)
                    .then(response => {
                        productionAct.items = response.data.data.items;
                        productionAct.produced_lot = response.data.data.produced_lot;
                    })
                    .catch(error => {
                        if (error && error.data && error.data.message) {
                            alert(error.data.message);
                        }
                    })
            },
            selectNomenclature(nomenclature) {
                this.selectedNomenclature = nomenclature
            },
            createProductionAct(task) {
                let params = {
                    tasks: task.ids.map(id => { return {id: id} }),
                    storage: {id: this.meta.storageFrom.id},
                    shift: {id: this.meta.productionShift.id},
                    nomenclature: {id: task.nomenclature_id},
                    start_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    use_proportional_raw_materials: 1
                };

                this.$http.post(PRODUCTION_ACTS_ENDPOINT, params)
                    .then(response => {
                        task.productionActs.push(response.data.data.id);
                        task.acts.push(response.data.data);
                    })
                    .catch(error => {
                        if (error.data && error.data.message) {
                            alert(error.data.message);
                        }
                    })
            },
            producedCount(tasks) {
                return sumBy(tasks, t => this.producedByTask(t));
            },
            requiredCount(tasks) {
                return sumBy(tasks, t => parseInt(t.required))
            },
            producedByTask(task) {
                return sumBy(task.acts, a => this.producedByAct(a));
            },
            transferredByTask(task) {
                return sumBy(task.acts, a => this.transferredByAct(a));
            },
            producedByAct(act) {
                return sumBy(act.items, i => parseInt(i.count));
            },
            transferredByAct(act) {
                return sumBy(act.material_transfer_acts, a => sumBy(a.items, i => i.nomenclature_lot.nomenclature_id === act.nomenclature_id ? parseInt(i.count) : 0));
            },
            nomenclatureLot(task) {
                if (!task.acts.length) {
                    return null;
                }

                return task.acts[0].produced_lot;
            },
            transferBatch() {
                this.$router.push({
                    name: 'MaterialTransferActCreate',
                    params: {
                        transfer: {
                            batchByProductionActId: true,
                            transmissionItems: this.transferBatchData(),
                            storageTo: this.meta.storageTo,
                            storage: this.meta.storageFrom,
                        }
                    }
                });
            },
            transferBatchData() {
                let batch = [];

                Object.keys(this.selectedTasks).map(k => {
                    let task = this.selectedTasks[k];
                    this.transferTaskData(task).map(i => {
                        batch.push(i);
                    });
                });

                return batch;
            },
            transfer(task) {
                let transferTaskData = this.transferTaskData(task);
                let batchByAct = false;
                if (transferTaskData.length > 1) {
                    batchByAct = true;
                }

                let transfer = {
                    batchByProductionActId: batchByAct,
                    transmissionItems: transferTaskData,
                    storageTo: this.meta.storageTo,
                    storage: this.meta.storageFrom,
                };

                if (!batchByAct) {
                    transfer.productionAct = {
                        id: task.productionActs[0]
                    }
                }

                this.$router.push({
                    name: 'MaterialTransferActCreate',
                    params: {
                        transfer: transfer
                    }
                });
            },
            transferTaskData(task) {

                let items = [];
                task.acts.map(a => {
                    let diff = this.producedByAct(a) - this.transferredByAct(a);
                    if (diff > 0) {
                        items.push({
                            nomenclature: {id: a.produced_lot.nomenclature_id},
                            nomenclatureLot: {id: a.produced_lot.id},
                            count: diff,
                            box: {
                                name: null
                            }
                        })
                    }
                });

                return items;
            },
            startPacking(task) {
                this.packableLot = this.nomenclatureLot(task);
                this.packableTaskId = task.ids[0];
                this.packableTaskAmount = task.required;
            },
            togglePacking() {
                if (this.packingTab === 'packing') {
                    this.$refs.packing.load();
                    return;
                }

                this.packingTab = 'packing';
            },
            toggleMovement() {
                if (this.packingTab === 'movement') {
                    this.$refs.movement.load();
                }

                this.packingTab = 'movement';
            },
        },
        created() {
            this.initFilters(this, FILTERS_PATTERN);
        }
    }
</script>

<style scoped lang="sass">
    .card
        margin-bottom: 0 !important

    .filters-wrapper
        display: flex
        align-items: center
        justify-content: space-between

    .filters-value
        display: flex
        font-size: 18px
        padding-left: 10px

    .deadline-control
        display: flex
        justify-content: space-around
        height: 100px
        align-items: center
        border: 1px solid #c8ced3
        font-size: 30px
        background-color: #cfecdd

        i:hover
            cursor: pointer
</style>

<style scoped>
    .link:hover {
        cursor: pointer;
    }

    .labeling-list-flex {
        display: flex;
    }

    .labeling-list {
        flex-basis: 65%;
        overflow-x: hidden;
    }

    .labeling-image {
        position: relative;
        flex-basis: 35%;
    }

    @media (max-width: 768px) {
        .labeling-list-flex {
            display: block;
        }

        .labeling-list {
            flex-basis: unset;
            overflow-x: auto;
        }

        .labeling-image {
            display: none;
        }

        .deadline-control {
            font-size: medium;
        }
    }
</style>
